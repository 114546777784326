import { type CSSProperties, forwardRef } from "react";
import type { StyledIcon } from "@styled-icons/styled-icon";

import { type PaletteKind, usePaletteColor } from "@rimo/frontend/hooks/usePaletteColor";

interface FeatherIconProps {
  Icon: StyledIcon;
  /**
   * "primary-main" | "error-main" | "warn-main" | "text-primary" | "text-secondary" | "currentColor" | string
   */
  color?: PaletteKind | "currentColor" | string | undefined;
  size?: number | string | undefined;
  height?: number | string | undefined;
  width?: number | string | undefined;
  strokeWidth?: number | string | undefined;
  fill?: string | undefined;
  style?: CSSProperties | undefined;
}

export const FeatherIcon = forwardRef<SVGSVGElement, FeatherIconProps>(
  ({ Icon, fill, color = "currentColor", size = 24, strokeWidth = 2, ...rest }, ref) => {
    const fallback = color;
    const themeColor = usePaletteColor(color, fallback);

    return <Icon ref={ref} color={themeColor} fill={fill} size={size} strokeWidth={strokeWidth} {...rest} />;
  }
);
