import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import { getPricingItemProps } from "@rimo/frontend/pages/AIEditorLP/Pricing";
import type { FC, MouseEventHandler} from "react";
import React, { useContext, useEffect, useState } from "react";
import type { BillingCycle, Grade, Subscription } from "@rimo/frontend/types/subscription";
import Image from "next/image";
import styled from "styled-components";
import { MOBILE_BREAKPOINT, useThemeMode } from "@rimo/ui-old";
import { PlanItem } from "./PlanItem";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import type { PricingItemProps } from "@rimo/frontend/pages/AIEditorLP/PricingItem";
import type {
  PlanProps,
  PlanStatus} from "@rimo/frontend/utils/subscription";
import {
  getGradeString,
  getNextBillingDate,
  getPlanProps,
  redirectCheckoutSession,
} from "@rimo/frontend/utils/subscription";
import Link from "next/link";
import { useSubscription } from "@rimo/frontend/hooks/useSubscription";
import { useIntlDateTime } from "@rimo/frontend/utils/date";
import { client } from "@rimo/frontend/api";
import { usePopupActions, usePopupIsOpen } from "@rimo/frontend/contexts/PopupsContext";
import { UserContext } from "@rimo/frontend/contexts/UserContext";
import { CircularProgress } from "@mui/material";
import { ToggleButton } from "./ToggleButton";

type InitialProps = {
  initialPage?: number;
  initialCycle?: BillingCycle;
  initialPlan?: string | null;
  isOpen?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SubscriptionModal: FC<InitialProps> = ({ initialPage, initialCycle, initialPlan, isOpen, setIsOpen }) => {
  const [page, setPage] = useState(initialPage ?? 0);

  const [cycle, setCycle] = useState<BillingCycle>(initialCycle ?? "monthly");
  const [plan, setPlan] = useState<string | null>(initialPlan ?? "Basic");
  const props = getPricingItemProps(cycle, plan, false);

  const popupIsOpen = usePopupIsOpen("subscription");
  const popupAction = usePopupActions("subscription");

  const {
    userState: { user },
  } = useContext(UserContext);
  const serviceId = process.env.NEXT_PUBLIC_AIEDITOR_SERVICE_ID;
  const { subscription, isLoading: isLoadingSubscription } = useSubscription(user, serviceId, popupIsOpen);

  const product_name =
    !subscription || Object.keys(subscription).length === 0
      ? "aieditor_free_monthly"
      : subscription?.product?.product_name;
  const [_, productGrade, productCycle] = product_name.split("_");
  const currentCycle: BillingCycle = productCycle as BillingCycle;
  const currentGrade: Grade = productGrade as Grade;
  const planProps = getPlanProps(currentGrade, currentCycle);

  const selectedPlan = props.find((p) => p.selected);
  const themeMode = useThemeMode();
  const interviewerSrc = themeMode === "light" ? "rimo_interviewer.svg" : "rimo_interviewer_white.svg";

  useEffect(() => {
    if (currentGrade === "basic") setPlan("Pro");
  }, [currentGrade]);

  useEffect(() => {
    if (initialCycle) setCycle(initialCycle);
    if (initialPlan) setPlan(initialPlan);
  }, [initialCycle, initialPlan]);

  const onClickPrevButton: MouseEventHandler<HTMLButtonElement> = (e): void => {
    e.preventDefault();
    setPage((prev) => prev - 1);
  };

  const onClickNextButton: MouseEventHandler<HTMLButtonElement> = (e): void => {
    e.preventDefault();
    setPage((prev) => prev + 1);
  };

  const onClose: MouseEventHandler<HTMLDivElement> = (e): void => {
    e.preventDefault();
    if (setIsOpen) {
      setIsOpen(false);
      setPage(1);
    } else {
      popupAction.close();
      setPage(0);
    }
  };

  const modalBodyList = [
    <PlanSelectionBody
      cycle={cycle}
      planProps={planProps}
      planItemProps={props}
      selectedPlan={selectedPlan}
      currentGrade={currentGrade}
      setCycle={setCycle}
      setPlan={setPlan}
      onClickNextButton={onClickNextButton}
    />,

    <ConfirmationBody
      cycle={cycle}
      planProps={planProps}
      selectedPlan={selectedPlan}
      subscription={subscription}
      setPage={setPage}
      onClickPrevButton={onClickPrevButton}
    />,

    <CompletedBody />,
    <IncompletedBody />,
  ];

  return popupIsOpen || isOpen ? (
    <ModalWrapper>
      <Modal>
        <Header>
          <Logo>
            <Image src={`/assets/${interviewerSrc}`} alt="logo" width={135} height={45} />
          </Logo>
          <CloseButton onClick={onClose}>
            <StyledCloseIcon />
          </CloseButton>
        </Header>

        {isLoadingSubscription ? <LoadingBody /> : modalBodyList[page]}
      </Modal>
      <Background onClick={onClose} />
    </ModalWrapper>
  ) : null;
};

const LoadingBody: React.FC = () => {
  return (
    <Body>
      <LoadingWrapper>
        <CircularProgress color="primary" size={48} thickness={2} />
      </LoadingWrapper>
    </Body>
  );
};

interface PlanSelectionBodyProps {
  cycle: BillingCycle;
  planProps: PlanProps;
  planItemProps: PricingItemProps[];
  selectedPlan: PricingItemProps | undefined;
  currentGrade: Grade;
  setCycle: React.Dispatch<React.SetStateAction<BillingCycle>>;
  setPlan: React.Dispatch<React.SetStateAction<string | null>>;
  onClickNextButton: MouseEventHandler<HTMLButtonElement>;
}

const PlanSelectionBody: React.FC<PlanSelectionBodyProps> = ({
  cycle,
  planProps,
  planItemProps,
  selectedPlan,
  currentGrade,
  setCycle,
  setPlan,
  onClickNextButton,
}) => {
  const { customT } = useClientTranslation();

  const currenPlanItemProps = planItemProps.find((p) => p.name.toLowerCase() === currentGrade);
  const serviceId = process.env.NEXT_PUBLIC_AIEDITOR_SERVICE_ID ?? "";

  const onClickToggleButton: MouseEventHandler<HTMLDivElement> = (e): void => {
    e.preventDefault();
    setCycle((prev) => (prev === "monthly" ? "yearly" : "monthly"));
  };

  const onClickPlanItem = (grade: string): MouseEventHandler<HTMLDivElement> => {
    return (e): void => {
      e.preventDefault();
      const name = getGradeString(grade as Grade);
      setPlan(name);
    };
  };

  const onClickContinueButton: MouseEventHandler<HTMLButtonElement> = (e): void => {
    e.preventDefault();
    if (!selectedPlan) return;

    if (currentGrade === "free") redirectCheckoutSession(serviceId, cycle, selectedPlan.name.toLowerCase() as Grade);
    else onClickNextButton(e);
  };

  return (
    <Body>
      <Plan>
        <Heading>{customT("プランを選択")}</Heading>
        <Upsell>
          <ToggleButton isActive={cycle === "yearly"} onClick={onClickToggleButton} />
          {cycle === "yearly" ? customT("月間課金に変更") : customT("年間課金に変更")}
        </Upsell>

        <PlanList>
          {Object.keys(planProps[cycle]).map((grade) => {
            const planItem = planItemProps.find((p) => p.name.toLowerCase() === grade);
            if (!planItem || grade === "free" || planProps[cycle][grade as Grade] === "current") return null;
            return <PlanItem {...planItem} onClickPlan={onClickPlanItem(grade)} key={`${grade}_${cycle}`} />;
          })}
          Current
          {currenPlanItemProps && <PlanItem {...currenPlanItemProps} isCurrentPlan={true} />}
        </PlanList>
      </Plan>

      <NavigationBar>
        <Link href="/interviews/plan">
          <SecondaryNavigationButton>{customT("プランの詳細")}</SecondaryNavigationButton>
        </Link>
        <PrimaryNavigationButton onClick={onClickContinueButton}>{customT("続ける")}</PrimaryNavigationButton>
      </NavigationBar>
    </Body>
  );
};

interface ConfirmationBodyProps {
  cycle: BillingCycle;
  planProps: PlanProps;
  selectedPlan: PricingItemProps | undefined;
  subscription: Subscription | null;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onClickPrevButton: MouseEventHandler<HTMLButtonElement>;
}

const ConfirmationBody: React.FC<ConfirmationBodyProps> = ({
  cycle,
  planProps,
  selectedPlan,
  subscription,
  setPage,
  onClickPrevButton,
}) => {
  const { customT } = useClientTranslation();

  const price = !selectedPlan ? null : cycle === "yearly" ? selectedPlan.price * 12 : selectedPlan.price;
  const grade = selectedPlan?.name.toLowerCase() as Grade;
  const intlDateTime = useIntlDateTime();
  const nextBillingDate = intlDateTime(getNextBillingDate(new Date(), cycle), {
    hour: false,
    minute: false,
    second: false,
  });
  const endCurrentPlanDate = intlDateTime(new Date(subscription?.end_date || Date.now()), {
    hour: false,
    minute: false,
    second: false,
  });

  const cautionNotes: { [key in PlanStatus]: string[] } = {
    none: [],
    current: [],
    upgrade: [
      "購入後、即座に新プランに移行します。",
      customT("{{nextBillingDate}}に自動更新されます。", { nextBillingDate }),
    ],
    downgrade: [
      customT("{{endCurrentPlanDate}}に請求が行われ、新プランに移行します。", { endCurrentPlanDate }),
      "移行前はいつでもキャンセルできます。",
    ],
  };

  const [isLoading, setIsLoading] = useState(false);

  const handlePurchase: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();

    if (isLoading) return;

    setIsLoading(true);
    try {
      const response = await client.post("/subscriptions/transfer_subscription", {
        current_price_id: subscription?.product.price_id,
        new_product_name: `aieditor_${grade}_${cycle}`,
        service_id: process.env.NEXT_PUBLIC_AIEDITOR_SERVICE_ID,
        transfer_type: planProps[cycle][grade],
      });

      if (response.status !== 200) {
        setPage(3);
      } else {
        setPage(2);
      }
    } catch (error) {
      console.error("リクエスト中にエラーが発生しました。", error);
      setPage(3);
    } finally {
      setIsLoading(false);
    }
  };

  return !selectedPlan ? null : (
    <Body>
      <Heading>Order Summary</Heading>

      <BillingSummary>
        <BillingItem>
          <PlanName>
            {customT(selectedPlan.name)} {customT(cycle)}
          </PlanName>
          <Price>
            {cycle === "yearly" && <MonthlyPrice>${selectedPlan.price * 15}</MonthlyPrice>} ${customT(price)}
          </Price>
        </BillingItem>
        <Total>
          <TotalLabel>Total</TotalLabel>
          <Price>${customT(price)}</Price>
        </Total>
      </BillingSummary>

      <CautionNotes>
        {cautionNotes[planProps[cycle][grade]].map((note, i) => (
          <Caution key={i}>{customT(note)}</Caution>
        ))}
      </CautionNotes>

      <NavigationBar>
        <SecondaryNavigationButton onClick={onClickPrevButton}>{customT("戻る")}</SecondaryNavigationButton>
        <PrimaryNavigationButton onClick={handlePurchase}>
          {isLoading && <Spinner />}
          <PurchaseText $isLoading={isLoading}>{customT("購入する")}</PurchaseText>
        </PrimaryNavigationButton>
      </NavigationBar>
    </Body>
  );
};

const CompletedBody: React.FC = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/interviews";
    }, 500);
  }, []);

  return (
    <Body>
      <OrderMessage>
        <OrderCompletedIcon>
          <OrderCheckIcon />
        </OrderCompletedIcon>
        <OrderStatus>Order Completed</OrderStatus>
        <p>Thank you for your purchase.</p>
      </OrderMessage>
    </Body>
  );
};

const IncompletedBody: React.FC = () => {
  return (
    <Body>
      <OrderMessage>
        <OrderIncompletedIcon>
          <OrderCloseIcon />
        </OrderIncompletedIcon>
        <OrderStatus>Order Incompleted</OrderStatus>
        <p>Sorry, we couldn't complete your order.</p>
      </OrderMessage>
    </Body>
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1;
  height: 100dvh;
  width: 100vw;
  top: 0;
  left: 0;
`;

const Modal = styled.div`
  box-sizing: border-box;
  width: 600px;
  max-height: 90vh;
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 2000;
  background: ${(p) => p.theme.vars.palette.background.experimental.layeredDefault};
  padding: 1.5rem;
  border-radius: 1rem;
  color: ${(p) => p.theme.vars.palette.text.secondary};

  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    width: 100%;
    top: auto;
    left: 0;
    bottom: 0;
    transform: none;
    border-radius: 1rem 1rem 0 0;
  }
`;

const Header = styled.div`
  height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Logo = styled.div``;

const Body = styled.div`
  margin: 2rem 0 0;
`;

const Plan = styled.div`
  margin: 2rem 0;
`;
const PlanList = styled.div`
  margin: 1rem 0;
`;

const Heading = styled.h2`
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: ${(p) => p.theme.vars.palette.text.primary};
`;

const Upsell = styled.div`
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const NavigationBar = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

const Button = styled.button`
  font-size: 0.85rem;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  background: none;
  font-weight: 600;
`;

const PrimaryNavigationButton = styled(Button)`
  background: ${(p) => p.theme.vars.palette.primary.main};
  color: #fff;
  position: relative;
`;

const SecondaryNavigationButton = styled(Button)`
  border: 1px solid ${(p) => p.theme.vars.palette.border};
  color: ${(p) => p.theme.vars.palette.text.primary};
  transition: 0.3s;

  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    &:hover {
      background: ${(p) => p.theme.vars.palette.background.light};
    }
  }
`;

const Spinner = styled.div`
  width: 1rem;
  height: 1rem;
  /*border: 1px #fff solid;*/
  border-top: 1px #fff solid;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 0.5rem);
  left: calc(50% - 0.5rem);

  @keyframes sp-anime {
    100% {
      transform: rotate(360deg);
    }
  }

  animation: sp-anime 1s infinite linear;
`;

const PurchaseText = styled.span<{ $isLoading: boolean }>`
  opacity: ${(p) => (p.$isLoading ? 0 : 1)};
`;

const CloseButton = styled.div`
  width: 2rem;
  height: 2rem;
  background: ${(p) => p.theme.vars.palette.background.light};
  position: relative;
  border-radius: 50%;
  cursor: pointer;
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Background = styled.div`
  height: 100%;
  width: 100%;
  background: #000000;
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1800;
`;

const BillingSummary = styled.div`
  margin: 2rem 0;
  color: ${(p) => p.theme.vars.palette.text.primary};
`;

const BillingItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
`;

const PlanName = styled.div`
  font-weight: 600;
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  font-weight: 600;
`;

const MonthlyPrice = styled.s`
  font-size: 0.85rem;
  color: ${(p) => p.theme.vars.palette.text.secondary};
`;

const Total = styled.div`
  border-top: 1px solid ${(p) => p.theme.vars.palette.border};
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const TotalLabel = styled.div`
  font-weight: 600;
`;

const CautionNotes = styled.div`
  margin: 2rem 0;
  line-height: 1.5rem;
`;

const Caution = styled.div`
  font-size: 0.85rem;
  color: ${(p) => p.theme.vars.palette.text.secondary};
`;

const OrderMessage = styled.div`
  width: 100%;
  padding: 2rem 0 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.vars.palette.text.primary};
`;

const OrderIcon = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const OrderCompletedIcon = styled(OrderIcon)`
  background: ${(p) => p.theme.vars.palette.primary.main};
`;

const OrderIncompletedIcon = styled(OrderIcon)`
  background: ${(p) => p.theme.vars.palette.primary.dark};
`;

const OrderCheckIcon = styled(CheckIcon)`
  width: 2rem;
  height: 2rem;
  color: #fff;
`;

const OrderCloseIcon = styled(CloseIcon)`
  width: 2rem;
  height: 2rem;
  color: #fff;
`;

const OrderStatus = styled.div`
  margin: 1rem 0 0.5rem;
  font-size: 1.5rem;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;
