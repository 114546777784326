import { useBackend } from "@rimo/frontend/hooks/useBackend";
import type {
  PersonalSubscriptions,
  SubscriptionItems,
  Products,
  Subscription,
  SubscriptionProduct,
} from "@rimo/frontend/types/subscription";
import type { User } from "firebase/auth";
import { useEffect, useState } from "react";

// サブスクリプションを取得し、扱いやすいように再マッピングする
export function useSubscription(
  user: User | undefined | null,
  serviceId: string | undefined,
  sholudFetch = true
): { subscription: Subscription | null; isSubscribed: boolean; isLoading: boolean; error: Error | undefined } {
  const {
    data: subscriptions,
    error,
    isValidating,
  } = useBackend<PersonalSubscriptions[]>(user && serviceId && sholudFetch ? `/subscriptions/${serviceId}` : null);

  const [subscription, setSubscription] = useState<Subscription | null>(null);

  useEffect(() => {
    if (subscriptions && subscriptions.length > 0) {
      setSubscription(mapPersonalSubscriptions(subscriptions)[0]);
    } else {
      setSubscription(null);
    }
  }, [subscriptions]);

  return {
    subscription,
    isSubscribed: subscription ? Object.keys(subscription).length > 0 : false,
    isLoading: isValidating,
    error,
  };
}

const mapPersonalSubscriptions = (personalSubscriptions: PersonalSubscriptions[]): Subscription[] => {
  return personalSubscriptions.map((sub) => {
    const mapProduct = (product: Products): SubscriptionProduct => ({
      price_id: product.price_id,
      product_name: product.product_name,
      service_id: product.service_id,
      type: product.type,
      user_type: product.user_type,
      pricing_model: product.pricing_model,
      billing_scheme: product.billing_scheme,
      currency: product.currency,
      billing_interval: product.billing_interval,
      flat_amount: product.flat_amount,
      unit_amount: product.unit_amount,
      unit_limit: product.unit_limit,
      unit_quantity: product.unit_quantity,
    });

    const subscriptionItem: SubscriptionItems | undefined = sub.SubscriptionItems[0];

    return {
      subscription_id: sub.subscription_id,
      customer_id: sub.customer_id,
      start_date: sub.start_date,
      end_date: sub.end_date,
      status: sub.status,
      subscription_item_id: subscriptionItem?.subscription_item_id ?? "",
      product: mapProduct(subscriptionItem?.Product),
      downgrade_product: subscriptionItem?.DowngradeProduct ? mapProduct(subscriptionItem.DowngradeProduct) : null,
      previous_product: subscriptionItem?.PreviousProduct ? mapProduct(subscriptionItem.PreviousProduct) : null,
    };
  });
};
