import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import type { MouseEventHandler, ReactNode } from "react";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import styled from "styled-components";

import { Body, BodyXSmall, Header1, MOBILE_BREAKPOINT } from "@rimo/ui-old";
import { useResponsive } from "@rimo/frontend/hooks/useResponsive";
import { Icon } from "@rimo/frontend/components/ui/Icon";

export interface PricingItemProps {
  /** プラン名 */
  name: string;
  /** 利用料金 */
  price: number;
  helperText?: ReactNode | undefined;
  /** 機能 */
  features: string[];
  simplifiedFeatures: string[];
  disable?: boolean;
  button?: ReactNode | undefined;
  popular?: boolean | undefined;
  selected?: boolean | undefined;
  fadeHelperText?: boolean | undefined;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  isLP?: boolean;
}

export const PricingItem = ({
  name,
  price,
  helperText,
  features,
  disable = false,
  button,
  popular = false,
  selected = false,
  fadeHelperText = false,
  onClick,
  isLP,
}: PricingItemProps) => {
  const { customT } = useClientTranslation();
  const { isMobile } = useResponsive();
  return (
    <Container $disable={disable} $isLP={isLP}>
      <Header>
        <Plan $color="inherit">
          {customT(name)}
          {popular && <Chip>Most Popular</Chip>}
        </Plan>
        <div>
          <AdornmentContainer>
            <div>
              <Price>
                ${price}
                <PriceLabel as="span" color="inherit">
                  /month
                </PriceLabel>
              </Price>
              <Fade $on={fadeHelperText}>
                <BodyXSmall color="inherit" weight="light">
                  {customT(helperText)}
                  {isMobile ? null : <>&nbsp;</>}
                </BodyXSmall>
              </Fade>
            </div>
            {isMobile && (
              <StyledIcon button name="chevron-bottom" size="18" onClick={onClick} value={name} $selected={selected} />
            )}
          </AdornmentContainer>
        </div>
      </Header>
      {isMobile && !selected ? null : (
        <>
          {!isMobile && Boolean(button) && <ButtonWrapper>{customT(button)}</ButtonWrapper>}
          <List>
            {features.map((v) => (
              <ListItem key={v}>
                <CheckOutlinedIcon color="primary" fontSize="small" sx={{ marginRight: 0.5 }} />
                <Body color="inherit">{customT(v)}</Body>
              </ListItem>
            ))}
          </List>
          {isMobile && Boolean(button) && <ButtonWrapper>{customT(button)}</ButtonWrapper>}
        </>
      )}
    </Container>
  );
};

const Container = styled.div<{ $disable: boolean; $isLP: boolean | undefined }>`
  --mobile-bg-color: ${(p) => p.theme.vars.palette.background.default};
  --text-color: ${(p) => p.theme.vars.palette.text.primary};

  overflow: hidden;
  border-radius: 16px;
  border: 1px solid ${(p) => p.theme.vars.palette.background.light};
  background: ${({ $disable, theme }) => ($disable ? theme.vars.palette.background.light : "none")};
  box-shadow: 0px 56px 40px -40px rgba(0, 0, 0, 0.08);
  color: var(--text-color);

  ${({ $isLP }) =>
    $isLP &&
    `
      --mobile-bg-color: #f9f9f9;
      --text-color: rgba(0,0,0,0.84);

      border: 1px solid #eeeeee;
      background: none;
    `}
`;

const Header = styled.div`
  display: flex;
  column-gap: 16px;
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    padding: 32px 24px 24px 24px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  @media (min-width: ${`${MOBILE_BREAKPOINT + 1}px`}) {
    padding: 32px 24px 0px 24px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Plan = styled(Header1)`
  display: inline-flex;
  align-items: center;
`;

const AdornmentContainer = styled.div`
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    display: flex;
    align-items: center;
    column-gap: 12px;
    text-align: right;
  }
  @media (min-width: ${`${MOBILE_BREAKPOINT + 1}px`}) {
  }
`;

const Price = styled.h3`
  position: relative;
  color: inherit;
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
  }
  @media (min-width: ${`${MOBILE_BREAKPOINT + 1}px`}) {
    font-size: 48px;
    font-weight: 900;
    line-height: 54px;
    padding-top: 24px;
    padding-bottom: 7px;
  }
  & > *,
  & + * {
    color: #666666;
  }
`;

const PriceLabel = styled.span`
  position: relative;
  left: 4px;
  font-size: 1.5rem;
  font-weight: 400;
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    bottom: 0px;
    font-size: 1rem;
  }
  @media (min-width: ${`${MOBILE_BREAKPOINT + 1}px`}) {
    bottom: 6px;
  }
`;

const StyledIcon = styled(Icon)<{ $selected: boolean }>`
  transform: ${({ $selected }) => ($selected ? "rotate(180deg)" : "rotate(0deg)")};
`;

const List = styled.ul`
  list-style: none;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    background: var(--mobile-bg-color);
    padding: 16px;
  }
  @media (min-width: ${`${MOBILE_BREAKPOINT + 1}px`}) {
    background: inherit;
    /* // todo kuriyama ボタン追加時に下記を適用する */
    /* padding: 0px 24px 24px 24px; */
    padding: 18px 24px 40px 24px;
  }
`;

const ListItem = styled.li`
  color: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Chip = styled.p`
  margin-left: 16px;
  height: fit-content;
  display: inline;
  padding: 4px 8px;
  background-color: rgba(0, 206, 193, 1);
  color: #ffffff;
  border-radius: 180px;
  border: none;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
`;

const ButtonWrapper = styled.div`
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    padding: 16px;
    background: var(--mobile-bg-color);
  }
  @media (min-width: ${`${MOBILE_BREAKPOINT + 1}px`}) {
    padding: 18px 24px 12px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Fade = styled.div<{ $on: boolean }>`
  color: inherit;
  transition: 0.5s;
  opacity: 1;
  color: #666;
  ${({ $on }) =>
    $on
      ? {
          opacity: 0,
        }
      : {}}
`;
